<template>
  <div>
    <h2>{{ msg }}</h2>
    <hr class="hr2">
    <div class="flex-items" id="text-container">
      <label for="inp-date">Pick out date</label>
      <input type="date" id="inp-date" v-model="date" required min="2023-03-05" max="2023-03-29" value="today">
      <label for="inp-select">Pick out currency</label>
      <select v-model="reqCurr" id="inp-select">
        <option v-for="curr in currencyList" :key="curr.id" :value="curr">{{ curr }}</option>
      </select>
    <router-link :to="{name: 'rates', params: {ticker: reqCurr}, query: {dt: tDate}}"><button class="fetch-btn">Fetch currency</button></router-link>
    <router-link :to="{name: 'rates', params: {ticker: 'all'}, query: {dt: tDate}}"><button class="fetch-btn">Fetch All currencies</button></router-link>
    </div>
    <hr class="hr1">
    <!-- <p>rev date: {{ tDate }}</p> -->
    <router-view></router-view>

  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: 'Dashboard exchange rates',
      currencyList: ['USD', 'EUR', 'GBP', 'PLN'],
      reqCurr: 'EUR',
      date: ''
    }
  },
  computed: {
    tDate: function () {
      return this.date.replace(/[-]/g, '')
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
}
.fetch-btn{
  position: relative;
  margin: .6rem .3rem;
  padding: .8rem;
}
#pick-out{
  text-align: right;
}
label{
  position: absolute;
  display: block;
  min-width: 10rem;
}
label[for="inp-date"]{
  left: 4.4rem;
  top: 14rem;
}
label[for="inp-select"]{
  left: 13.5rem;
  top: 14rem;
}
</style>
