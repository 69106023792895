import Vue from 'vue'
import VueRouter from 'vue-router'
import DashBoard from '@/components/dashboard-layout'
import Rates from '@/components/rates-card'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashBoard,
    children: [
      {
        path: '/rates/:ticker?',
        name: 'rates',
        component: Rates
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../components/rates-card')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
