<template>
  <div>
    <div class="flex-items">
      <h3 class="x-text">{{ msg }}</h3>
      <router-link :to="{name: 'home'}">Back</router-link>
    </div>

    <hr>
    <table v-show="NBURates">
      <tr>
        <th>Code</th><th>Currency</th><th>Rate</th><th>Ticker</th><th>Date</th>
      </tr>
      <tr v-for="cur in NBURates" :key="cur.r030">
        <td>{{ cur.r030 }}</td><td>{{ cur.txt }}</td><td>{{ cur.rate }}</td><td>{{ cur.cc }}</td><td>{{ cur.exchangedate }}</td><td>{{ cur.id }}</td>
      </tr>
    </table>
    <p>Current currency: {{ ticker }}</p>
    <!-- <p>All rates: {{ allRates }}</p> -->
    <p>Date: {{ reqDate }}</p>
  </div>
</template>

<script>
//  samples of URLs to NBU open resource (1st - all currencies, 2nd - with currency and date)
// 'https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json',
// 'https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=20230327&json',
export default {
  data () {
    return {
      msg: 'NBU foreign currencies exchange rates',
      reqDate: '20230327',
      ticker: '',
      allRates: '',
      NBURates: '',
      url1: 'http://localhost:3000',
      url2: '/nbu_defined',
      url3: '/nbu_all_rates'

    }
  },
  computed: {
    fetchNBU1: function () {
      return `https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=${this.ticker}&date=${this.reqDate}&json`
    },
    fetchNBU2: function () {
      return `https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=&date=${this.reqDate}&json`
    }
  },
  watch: {
    $route (to, from) {
      if (this.$route.params.ticker !== 'all') {
        this.ticker = this.$route.params.ticker
      } else {
        this.allRates = this.$route.params.ticker
      }
      this.reqDate = this.$route.query.dt
    },
    ticker () {
      this.getRatesNBU(this.fetchNBU1)
    },
    allRates () {
      this.getRatesNBU(this.fetchNBU2)
    },
    NBURates: function () {
      if (this.NBURates.length === 1) {
        this.localServerHandler(this.url2, 'DEFINED')
      } else {
        this.allRates = ''
        this.localServerHandler(this.url3, 'ALL-RATES')
      }
    }
  },
  methods: {
    localServerHandler (url, section) {
      this.$http.get(this.url1 + url + '/1')
        .then(resp => {
          console.log(resp)
          if (resp.status === 200) {
            this.$http.put(this.url1 + url + '/1', this.NBURates)
              .then(resp => {
                if (resp.status === 200) {
                  console.log(`**${section} currency has been updated**`)
                }
              })
              .catch(err => console.log(`**Put ${section} currency Error** `, err))
          }
        })
        .catch(err => {
          if (err.status === 404) {
            this.$http.post(this.url1 + url, this.NBURates)
              .then(resp => {
                if (resp.status === 201) {
                  console.log(`**${section} currency has been saved in first time**`)
                }
              })
              .catch(err => console.log(`**Saving ${section} currency Error** `, err))
          }
        })
    },
    getRatesNBU (url) {
      this.$http.get(url)
        .then(r => r.json())
        .then(v => {
          let counter = 1
          const newV = []
          v.forEach(el => {
            el.id = counter
            newV.push(el)
            counter = counter + 1
          })
          this.NBURates = newV
        })
        .catch(err => console.log('**Receiving from NBU Error**', err))
    },
    setData () {
      this.reqDate = this.$route.query.dt
      if (this.$route.params.ticker === 'all') {
        this.allRates = this.$route.params.ticker
      } else if (this.$route.params.ticker !== 'all') {
        this.ticker = this.$route.params.ticker
      }
      // console.log('BeforeRouteEnter date: ', this.$route.query.dt)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setData()
    })
  }
}
</script>
