<template>
  <div id="app">
    <h1>{{ msg | UpperCase}}</h1>
    <hr class="hr1">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      msg: 'main page'
    }
  }
}
</script>

<style scoped>
#app{
  min-width: 700px;
  overflow: auto;
}
</style>
